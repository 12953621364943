import * as React from "react";
import Layout from "../components/main/layout/Layout";
import Contact from "../components/main/site/form/Contact";
const kontakt = () => {
  return (
    <Layout>
      <Contact url= {'https://app.businesshamster.de:3000/contact'} />
    </Layout>
  );
};
export default kontakt;
